// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-cv-page-js": () => import("./../../../src/templates/cv-page.js" /* webpackChunkName: "component---src-templates-cv-page-js" */),
  "component---src-templates-events-page-js": () => import("./../../../src/templates/events-page.js" /* webpackChunkName: "component---src-templates-events-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-phd-page-js": () => import("./../../../src/templates/phd-page.js" /* webpackChunkName: "component---src-templates-phd-page-js" */),
  "component---src-templates-publications-page-js": () => import("./../../../src/templates/publications-page.js" /* webpackChunkName: "component---src-templates-publications-page-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-teaching-page-js": () => import("./../../../src/templates/teaching-page.js" /* webpackChunkName: "component---src-templates-teaching-page-js" */)
}

